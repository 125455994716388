angular.module('LeasePilot').directive('downloadLease', [
  'DocumentService',
  '$rootScope',
  '$filter',
  'ApiService',
  'LeaseEditorService',
  'ProcessStatusService',
  'DownloadService',
  '$mdDialog',
  'leaseExportService',
  function(
    DocumentService,
    $rootScope,
    $filter,
    ApiService,
    LeaseEditorService,
    ProcessStatusService,
    DownloadService,
    $mdDialog,
    leaseExportService,
  ) {
    return {
      restrict: 'E',
      replace: true,
      templateUrl: 'download_lease.html',
      link: function($scope) {
        $scope.processing = false;
        $scope.eventName = 'downloadLease';
        var type =
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        $scope.downloadErrorMessage =
          'Oops... something went wrong with the download process, please wait a couple of seconds and try again.';

        var iframe = angular.element('<iframe/>', {
          id: 'download-lease',
          name: 'download',
          style:
            'position: absolute; top: 0; left: 0; width: 1px; height: 1px; z-index: -1; opacity: 0;',
          'event-name': $scope.eventName,
        });

        $scope.downloadDocument = function() {
          window.track.event(new DownloadDocumentEvent({
            context: $rootScope.getContext(),
          }));

          $scope.processing = true;
          ProcessStatusService.start("download");
          if (window.lease) {
            downloadManager.prepareDownload("downloadLease");
          } else {
            var src =
              "/" +
              pluralize($rootScope.lease.type).toLowerCase() +
              "/" +
              $rootScope.lease.id;
            angular
              .element('iframe[event-name="' + $scope.eventName + '"]')
              .remove();
            angular.element(iframe).attr("src", src);
            angular.element("body").append(iframe);
          }
        };

        $scope.downloadSubdocument = function(subdocumentName) {
          window.track.event(new DownloadDocumentEvent({
            context: $rootScope.getContext(),
          }));

          $scope.processing = true;
          ProcessStatusService.start("download");
          if (window.lease) {
            const startNode = document.querySelector(`subdocument[name='${subdocumentName}']`);
            const endNode = document.querySelector(`subdocument-end[name='${subdocumentName}']`);
            const filename = startNode.getAttribute("filename");
            downloadManager.downloadSubdocument(subdocumentName, filename);
          } else {
            var src =
              "/" +
              pluralize($rootScope.lease.type).toLowerCase() +
              "/" +
              $rootScope.lease.id;
            angular
              .element('iframe[event-name="' + $scope.eventName + '"]')
              .remove();
            angular.element(iframe).attr("src", src);
            angular.element("body").append(iframe);
          }
        };

        $scope.$on($scope.eventName, function(event, data) {   
          $scope.processing = true;
          ProcessStatusService.start("download");
          $scope.safeApply();
          
          console.log(new Date().getTime() + ' on event: ', $scope.eventName);
          console.log(new Date().getTime() + ' data ', data);

          ////////////////////////////////////////////////
          // PLEASE KEEP COMMENT IN PLACE - this used for document conversion testing purposes [Itzik S]
          // DownloadService.download(data.file, "html/text", "input_document.html");
          ////////////////////////////////////////////////

          ApiService.htmlToWord(data.file, window.ASSETS_DESIRED_STYLES)
            .then(function successCallback(response) {
              $scope.processing = false;
              ProcessStatusService.end('download');
              $scope.safeApply();
              DownloadService.download(response.data, type, LeaseEditorService.getFileName());
              console.log(
                new Date().getTime() + ' download lease finished successfully',
              );

              // Trigger update status - it'll only update the status if necessary
              updateStatus();
            },
            function errorCallback(response) {
              $scope.processing = false;
              ProcessStatusService.end('download');
              $scope.safeApply();
              console.log(response);
              alert($scope.downloadErrorMessage);
            },
          );
        });

        function updateStatus() {
          if (window.user.company.name === 'kite') {
            const statusPickerElement = document.querySelector('status-picker');
            const statusPickerScope = angular.element(statusPickerElement.firstElementChild).scope();
  
            if (statusPickerScope.exportStatusId) {
              statusPickerScope.selectStatus(statusPickerScope.exportStatusId);
            }
          }
        }
      },
    };
  },
]);
